// profile.guard.ts
import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  GuardResult,
  MaybeAsync,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  combineLatest,
  debounce,
  debounceTime,
  first,
  forkJoin,
  from,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { AuthService } from '../auth.service';
import { WorkspaceService } from '@solid/services/workspace.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root',
})
export class HasPermissions implements CanActivate, CanActivateChild {
  //#region Injections
  private workspaceService = inject(WorkspaceService);
  private permissionsService = inject(NgxPermissionsService);
  //#endregion

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  private hasPermissions(permissions: string[]): Observable<boolean> {
    return from(this.permissionsService.hasPermission(permissions));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const requiredPermissions = route.data['permissions']['only'] as string[];
    const redirectTo = route.data['permissions']['redirectTo'];

    if (!requiredPermissions || !requiredPermissions.length) {
      return of(true);
    }

    return combineLatest([
      this.workspaceService.workspace$,
      this.workspaceService.workspaceUser$,
    ]).pipe(
      debounceTime(100),
      take(1),
      switchMap(() => this.hasPermissions(requiredPermissions)),
      tap((hasPermissions) => {
        if (hasPermissions) return;

        if (redirectTo && redirectTo.navigationCommands) {
          this.router.navigate(redirectTo.navigationCommands);
        }
      }),
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const data = childRoute.data as any;

    const requiredPermissions = data?.permissions?.only as string[];
    const redirectTo = data?.permissions?.redirectTo;

    if (!requiredPermissions || !requiredPermissions.length) {
      return of(true);
    }

    return combineLatest([
      this.workspaceService.workspace$,
      this.workspaceService.workspaceUser$,
    ]).pipe(
      debounceTime(100),
      take(1),
      switchMap(() => this.hasPermissions(requiredPermissions)),
      tap((hasPermissions) => {
        if (hasPermissions) return;

        if (redirectTo && redirectTo.navigationCommands) {
          this.router.navigate(redirectTo.navigationCommands);
        }
      }),
    );
  }
}
